import common from './pt/common';

export default {
  common,
  topHeader: {
    eye: 'EYEMOBILE',
  },
  options: {
    today: 'Hoje',
    hour: 'Hora',
    thisWeek: 'Esta semana',
    thisMonth: 'Este mês',
    thisYear: 'Este ano',
    otherPeriod: 'Outro período',
    dayOfWeek: 'Dia da semana',
    dayOfMonth: 'Dia do mês',
    dayOfPeriod: 'Dia do período',
    weekOfMonth: 'Semana do mês',
    all: 'Todo o período',
    products: 'produtos',
  },
  main: {
    total: 'TOTAIS',
    totalSingle: 'TOTAL',
    totalValue: 'TOTAL',
    outstandingAmount: 'VALOR TOTAL EM ABERTO',
    services: 'SERVIÇOS',
    expensesXincome: 'DESPESAS X RECEITAS',
    recipes: 'RECEITAS',
    expenses: 'DESPESAS',
    commands: 'COMANDAS',
    products: 'PRODUTOS',
    shifts: 'CAIXAS / TURNOS',
    points: 'OPERAÇÕES',
    telemetries: 'DISPOSITIVOS',
    operators: 'OPERADORES',
    groupProducts: 'GRUPO DE PRODUTOS',
    amountPaidCommands: 'VALOR PAGO PELAS COMMANDAS',
    pendingValue: 'VALOR PENDENTE',
    pendingValues: 'Valores pendentes',
    busyCards: 'COMANDAS OCUPADAS',
    serviceChargePercent: 'Taxa de Serviço',
    serviceIncluded: 'Importante: a taxa de serviço está incluída no total',
    serviceNotIncluded:
      'Importante: a taxa de serviço não está incluída no total',
    totalOpen: 'TOTAL ABERTOS',
    lastSyncAtTime: 'SINCRONIZADOS A MENOS TEMPO',
    aZ: 'A - Z',
    high: 'Bateria carregada',
    average: 'Em bom estado',
    low: 'Bateria baixa',
    critical: 'Bateria crítica',
    residual: 'RESIDUAL',
    amountSale: 'BAIXA DE PRODUTOS',
    infoTag:
      'Os valores agrupados por forma de pagamento não consideram as vendas de tag',
    details: 'DETALHES',
    saleCredit: 'VENDA DE CRÉDITOS',
    saleTicket: 'VENDA DE TICKET',
    returnOfResidualBalance: 'DEVOLUÇÃO DE SALDO RESIDUAL',
    tags: 'TAGS',
    tagsReleased: 'TAGs LIBERADAS',
    tagsReturned: 'TAGs DEVOLVIDAS',
    returned: 'DEVOLVIDA',
    productionTags: 'TAGs PRODUÇÃO',
    productionTagsReleased: 'TAGs LIBERADAS PARA PRODUÇÃO',
    productionTagsReturned: 'TAGs DEVOLVIDAS PARA PRODUÇÃO',
    balanceTag: 'SALDO DE TAGs',
    tagsNotReturned: 'TAGs NÃO DEVOLVIDAS',
    valueForReturn: 'VALOR PARA DEVOLUÇÃO',
    creditProduction: 'CRÉDITO PARA PRODUÇÃO',
    debitProduction: 'CONSUMO DE PRODUÇÃO',
    residualProduction: 'RESIDUAL DE PRODUÇÃO',
    otherInfo: 'OUTRAS INFORMAÇÕES',
    ticketAverage: 'TICKET MÉDIO',
    eyeplace: 'EYEPLACE',
    selectDate:
      'Selecione uma data de início e uma data final para exibir resultados',
    key: 'item',
    key_plural: 'itens',
    events: 'Eventos',
    draft: 'Testes',
    statistics: 'Estatísticas',
    lessThanAMinute: '< 1 min',
    record: 'REGISTRO',
    records: 'REGISTROS',
  },
  roles: {
    1000: 'Administrador de sistema',
    1100: 'Suporte',
    1200: 'Financeiro',
    2000: 'Distribuidor',
    2100: 'Técnico',
    3000: 'Cliente (Administrador)',
    3100: 'Cliente (resultados)',
    3200: 'Gestor de operações',
    3210: 'Operador de atendimento',
    3220: 'Operador de venda',
    3230: 'Operador de estoque',
    no_role: 'Sem função definida',
  },
  status: {
    1: 'Em aprovação',
    2: 'Reprovado',
    3: 'Configuração',
    4: 'Em andamento',
    5: 'Encerrado',
    6: 'Em faturamento',
  },
  paymentFrequency: {
    1: 'Diária',
    2: 'Mensal',
    3: 'Anual',
    4: 'Esporádica',
  },
  button: {
    notNow: 'Agora não',
    exportCsv: 'Exportar Planilha',
    exportXmls: 'Exportar XMLS',
    save: 'Salvar',
    next: 'Próximo',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    search: 'Pesquisar',
    understood: 'Entendi',
  },
  single: {
    participation: 'Participação',
  },
  breadcrumb: {
    distributionCenters: 'Centros de distribuição',
    devices: 'Dispositivos',
    devicesGroup: 'Grupo de dispositivos',
    deviceStatus: 'Status do dispositivo',
    proposals: 'Propostas',
    tenants: 'Clientes',
    partners: 'Revendas',
    agentRelease: 'Agente de impressão',
    settings: 'Configurações',
  },
  navigation: {
    devices: 'Dispositivos',
    distributionCenters: 'Centros de Distribuição',
    devicesGroup: 'Grupo de dispositivos',
    deviceStatus: 'Status do dispositivo',
    proposals: 'Propostas',
    tenants: 'Clientes',
    partners: 'Revendas',
    agentRelease: 'Agente de impressão',
    settings: 'Configurações',
  },
  transactionType: {
    purchase: 'Venda de crédito/ticket',
    cashOut: 'Retirada',
    cashIn: 'Suprimento',
    cashBack: 'Devolução de valor',
    sale: 'Baixa de produto',
    newTag: 'Liberação de tag',
    returnTag: 'Devolução de tag',
    transferFrom: 'Transferência (origem)',
    transferTo: 'Transferência (destino)',
    employeeIn: 'Pagamento de funcionário',
    employeeOut: 'Saque de funcionário',
    backstagePurchase: 'Inserção crédito produção',
    backstageSale: 'Consumo de produção',
    backstageNewTag: 'Liberação tag produção',
    backstageReturnTag: 'Devolução tag produção',
    order: 'Pedido',
  },
  productMeasure: {
    unity: 'Un',
    kilogram: 'Kg',
    liter: 'L',
  },
  key: 'item',
  key_plural: 'items',
  formErrors: {
    youNeedToFillAllFields: 'Ops! Você precisa preencher todos os campos',
    youNeedToFillPointName: 'Ops! Você precisa preencher o nome da operação',
    youNeedToFillPointLabel:
      'Ops! Você precisa preencher o nome abreviado da operação ',
    youNeedToFillAccount:
      'Ops! Você precisa selecionar uma lista de comandas para continuar',
    youNeedToFillLayout: 'Ops! Você precisa preencher o layout de impressão',
    youNeedToFillServiceCharge: 'Ops! Você precisa preencher a taxa de serviço',
    youNeedToFillDiscount: 'Ops! Você precisa preencher o desconto',
    youNeedToFillZipCode: 'Ops! Você precisa preencher o CEP',
    youNeedToFillAddress: 'Ops! Você precisa preencher o endereço',
    youNeedToFillDistrict: 'Ops! Você precisa preencher o bairro',
    youNeedToFillCity: 'Ops! Você precisa preencher a cidade',
    youNeedToFillState: 'Ops! Você precisa preencher o estado',
    youNeedToFillPhone: 'Ops! Você precisa preencher o telefone',
    youNeedToFillFiscalDocument: 'Ops! Você precisa preencher o documento',
    youNeedToFillFiscalName: 'Ops! Você precisa preencher a razão social',
    youNeedToFillFiscalLabel: 'Ops! Você precisa preencher o nome fantasia',
    youNeedToFillFiscalIm: 'Ops! Você precisa preencher a inscrição municipal',
    youNeedToFillCrt: 'Ops! Você precisa preencher o regime tributário',
    youNeedToFillSettlementInfo:
      'Ops! Você precisa preencher as informações do estabelecimento pois esta operação irá emitir documento fiscal',
    youNeedToFillStateRegistration:
      'Ops! Você precisa preencher a inscrição estadual',
    youNeedToFillCsc: 'Ops! Você precisa preencher o CSC',
    youNeedToFillFiscalToken: 'Ops! Você precisa preencher o token fiscal',
    youNeedToFillParticipationPercentage:
      'Ops! Você precisa preencher a porcentagem da participação',
    youNeedToFillRecipient: 'Ops! Você precisa selecionar um recebedor',
    youNeedToFillIbgeCode: 'Ops! Você precisa preencher o código IBGE',
    youNeedToFillPayType:
      'Ops! Você precisa incluir pelo menos uma forma de pagamento',
    youNeedToFillMenu: 'Ops! Você precisa preencher a lista de produtos',
    youNeedToFillLoyaltyRatio:
      'Ops! Você precisa preencher o valor para a fidelidade',
    invalidStateRegistration:
      'A Inscrição Estadual informada não é válida, verifique o seu número ou o estado informado',
    totalParticipationError:
      'Ops! O total das participações não podem ultrapassar 100%',
    totalParticipationError2: 'Ops! O total das participações deve ser 100%',
    participationPercentageError:
      'Ops! A porcentagem da participação não pode ultrapassar 100%',
    participationPercentageFormatError: 'Ops! A porcentagem não é válida',
    participationPayTaxesError:
      'Ops! Já existe uma participação responsável pelas taxas',
    participationPointOwnerError:
      'Ops! Já existe uma participação pertencente à operação',
    participationNoPayTaxesError:
      'Ops! Pelo menos um recebedor deve ser responsável pelas taxas',
    participationNoPointOwnerError:
      'Ops! Pelo menos um recebedor deve percenter à operação',
    participationAlreadyAdded: 'Ops! Este recebedor já foi adicionado',
    payTypeAlreadyAdded: 'Ops! Esta forma de pagamento já foi adicionada',
    userAlreadyAdded: 'Ops! Este usuário já foi adicionado',
    invalidFiscalSequenceNumber:
      'Ops! A numeração da última nota fiscal emitida deve ser maior que zero',
    invalidFiscalSequenceSerie:
      'Ops! A série da nota fiscal deve ser maior que zero',
    invalidFiscalCertificate:
      'Ops! É necessário enviar um certificado para emissao de notas fiscais',
    selectANewDate: 'Ops! É necessário selecionar uma nova data',
    initialDateAfterFinalDate:
      'Ops! A data inicial não pode ser depois da data final',
    finalDateBeforeInitialDate:
      'Ops! A data final não pode ser antes da data inicial',
    attachmentMustBeHtml: 'Ops! O template deve ser do tipo HTML',
    youNeedToFillBatchWritingPayType:
      'Ops! Você precisa preencher a forma de pagamento para gravação em massa de tags',
    deviceAlreadyAdded: 'Ops! Este dispositivo já está cadastrado',
    deviceAlreadyAddedOnTheList:
      'Ops! Este dispositivo já foi adicionado na lista',
    distributionCenterAlreadyAdded:
      'Ops! Este centro de distribuição já foi adicionado',
    atLeastOneDeviceMustBeAdded:
      'Ops! Você precisa incluir pelo menos um dispositivo',
    youNeedToFillSerialNumber: 'Ops! Você precisa preencher o número de série',
    devicesGroupAlreadyAdded:
      'Ops! Este grupo de dispositivos já foi adicionado',
    deviceStatusAlreadyAdded:
      'Ops! Este status do dispositivo já foi adicionado',
  },
  agentRelease: {
    device: 'Dispositivo',
    version: 'Versão',
    url: 'URL',
    createdAt: 'CRIADO EM',
    printAgent: 'Agente de impressão',
    manageVersions: 'Gerenciar Versões',
    addVersion: 'Adicionar versão',
    notVersions: 'Nenhuma versão encontrada',
    toLookFor: 'Pesquisar',
    search: 'Buscar',
    attention: 'Atenção',
    deleteConfirm: 'Você tem certeza que deseja excluir esta versão?',
    delete: 'Excluir',
    addVersion: 'Adicionar versão',
    addFile: 'Adicionar arquivo',
    create: 'Criar',
    writeVersion: 'Digite a versão',
    selectDevice: 'Selecione o dispositivo',
    failedAddVersion: 'Falha ao adicionar versão',
    removeFile: 'Remover arquivo',
    alreadyReleaseVersion: 'Já existe um agente com esta versão',
    copy: 'Copiar',
    copied: 'Copiado!',
  },
};
