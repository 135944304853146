/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { connect } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';

import { ReactComponent as DevicesGroups } from '../assets/ic_sidebar_device_group.svg';
import { ReactComponent as DevicesStatus } from '../assets/ic_sidebar_device_status.svg';

import { PermissionContext } from '../contexts/PermissionContext';
import { toggleSideMenu } from '../store/modules/sideMenu';
import PrintIcon from '../assets/ic_print-solid';

const mapStateToProps = ({ SideMenu }) => ({
  menuOpen: SideMenu.menuOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleSideMenu }, dispatch);

const Navigation = ({ location, toggleSideMenu, menuOpen }) => {
  const { t } = useTranslation();

  const { hasPermission } = React.useContext(PermissionContext);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const activeRoute = (routeName) => {
    return location.pathname.startsWith(routeName) ? 'active' : '';
  };

  const closeSideMenu = () => {
    if (isMobile) {
      toggleSideMenu(false);
    }
  };

  return (
    <nav
      id="nav"
      className={clsx(
        'navigation',
        'custom-vscroll',
        menuOpen ? 'open' : 'closed',
      )}
    >
      <div className="nav-menu">
        <div className="nav-header">
          <div className="nav-header-icon">
            <div className="logo-element" />
          </div>
          <div className="flex justify-end nav-header-icon mobile">
            <div className="ic-close" onClick={closeSideMenu} />
          </div>
        </div>
        {hasPermission({ name: 'devices', level: 'view' }) && (
          <div className={`menu-content ${activeRoute('/devices')}`}>
            <Link to="/devices" onClick={closeSideMenu}>
              <div className="ic ic-devices" />
              <span className="nav-label">{t('navigation:devices')}</span>
            </Link>
          </div>
        )}
        {hasPermission({ name: 'devices', level: 'edit' }) && (
          <div
            className={`menu-content ${activeRoute('/distributionCenters')}`}
          >
            <Link to="/distributionCenters" onClick={closeSideMenu}>
              <RoomIcon />
              <span className="nav-label">
                {t('navigation:distributionCenters')}
              </span>
            </Link>
          </div>
        )}
        {hasPermission({ name: 'devices', level: 'edit' }) && (
          <div className={`menu-content ${activeRoute('/devicesGroup')}`}>
            <Link to="/devicesGroup" onClick={closeSideMenu}>
              <DevicesGroups />
              <span className="nav-label">{t('navigation:devicesGroup')}</span>
            </Link>
          </div>
        )}
        {hasPermission({ name: 'devices', level: 'edit' }) && (
          <div className={`menu-content ${activeRoute('/deviceStatus')}`}>
            <Link to="/deviceStatus" onClick={closeSideMenu}>
              <DevicesStatus />
              <span className="nav-label">{t('navigation:deviceStatus')}</span>
            </Link>
          </div>
        )}
        {hasPermission({ name: 'proposals', level: 'view' }) && (
          <div className={`menu-content ${activeRoute('/proposals')}`}>
            <Link to="/proposals" onClick={closeSideMenu}>
              <DescriptionIcon />
              <span className="nav-label">{t('navigation:proposals')}</span>
            </Link>
          </div>
        )}
        {hasPermission({ name: 'tenants', level: 'view' }) && (
          <div className={`menu-content ${activeRoute('/tenants')}`}>
            <Link to="/tenants" onClick={closeSideMenu}>
              <PersonIcon />
              <span className="nav-label">{t('navigation:tenants')}</span>
            </Link>
          </div>
        )}
        {hasPermission({ name: 'partners', level: 'view' }) && (
          <div className={`menu-content ${activeRoute('/partners')}`}>
            <Link to="/partners" onClick={closeSideMenu}>
              <BusinessIcon />
              <span className="nav-label">{t('navigation:partners')}</span>
            </Link>
          </div>
        )}
        {
          <div className={`menu-content ${activeRoute('/printAgentReleases')}`}>
            <Link to="/printAgentReleases" onClick={closeSideMenu}>
              <PrintIcon />
              <span className="nav-label">{t('navigation:agentRelease')}</span>
            </Link>
          </div>
        }
        {hasPermission({ name: 'admin', level: 'tools' }) && (
          <div className={`menu-content ${activeRoute('/settings')}`}>
            <Link to="/settings" onClick={closeSideMenu}>
              <SettingsIcon />
              <span className="nav-label">{t('navigation:settings')}</span>
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

Navigation.defaultProps = {
  menuOpen: undefined,
};

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
  menuOpen: PropTypes.func,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Navigation);
