import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import RichTextField from '../../components/RichTextField';

import gateway from '../../utils/gateway';
import { format } from '../../utils/decimal';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={<CheckIcon />}
      >
        Confirmar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const ItemSchema = Yup.object().shape({
  contract_product: Yup.object().required('Campo obrigatório'),
  quantity: Yup.number()
    .required('Campo obrigatório')
    .min(1, 'Quantidade inválida'),
  price: Yup.number()
    .required('Campo obrigatório')
    .min(1, 'Preço inválido'),
  discount_type: Yup.number().required('Campo obrigatório'),
  discount: Yup.number()
    .nullable()
    .when('discount_type', {
      is: -1,
      then: Yup.number().nullable(),
      otherwise: Yup.number()
        .nullable()
        .required('Campo obrigatório'),
    }),
  debit_percentage: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.debit_percentage_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  credit_percentage: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.credit_percentage_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  credit_percentage_2_6: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.credit_percentage_2_6_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  credit_percentage_7_12: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.credit_percentage_7_12_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  debit_antecipation_percentage: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.debit_antecipation_percentage_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  credit_antecipation_percentage: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.credit_antecipation_percentage_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  ecommerce_transaction_fee: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.ecommerce_transaction_fee_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
  ecommerce_transaction_percentage: Yup.number()
    .nullable()
    .when(
      ['contract_product', 'amendment'],
      (contract_product, amendment, schema) => {
        if (
          contract_product !== null &&
          contract_product.ecommerce_transaction_percentage_enabled &&
          !amendment
        ) {
          return Yup.number()
            .nullable()
            .required('Campo obrigatório');
        }
        return Yup.number().nullable();
      },
    ),
});

export default function EditItemDialog({
  open,
  onClose,
  onSubmit,
  item,
  amendment,
  contractType,
  addSubscription,
}) {
  const formRef = React.useRef();
  const [productSaas, setProductSaas] = useState([]);
  const [productServices, setProductServices] = useState([]);
  const [productOthers, setProductOthers] = useState([]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const fetchProducts = async (search) => {
    const itemsSaas = [];
    const itemsServices = [];
    const itemsOthers = [];

    let type = [0, 1, 2];

    if (addSubscription) {
      type = [1];
    } else if (contractType === 6) {
      type = [0, 3];
    } else if (contractType === 7) {
      type = [0, 1, 2, 3];
    }

    const params = {
      recurrency_type: type,
      plan: 3,
    };
    if (search && search.length > 0) {
      params.search = search;
    }

    try {
      const response = await gateway.get('/billing/contracts/products', {
        params,
      });
      response.data.contract_products.map((product) => {
        switch (product.type) {
          case 1:
            itemsServices.push(product);
            break;
          case 2:
            itemsOthers.push(product);
            break;
          default:
            itemsSaas.push(product);
            break;
        }
      });
      setProductSaas(itemsSaas);
      setProductServices(itemsServices);
      setProductOthers(itemsOthers);
    } catch (err) {
      console.error(err);
      showError();
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [contractType]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Novo Item
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              amendment,
              contract_product: item ? item.contract_product : {},
              quantity: item ? item.quantity : '',
              price: item ? item.price : 0,
              discount_type:
                item && item.discount_type !== null ? item.discount_type : -1,
              discount: item ? item.discount : null,
              observations: item ? item.observations : null,
              debit_percentage:
                item && item.debit_percentage ? item.debit_percentage : null,
              credit_percentage:
                item && item.credit_percentage ? item.credit_percentage : null,
              credit_percentage_2_6:
                item && item.credit_percentage_2_6
                  ? item.credit_percentage_2_6
                  : null,
              credit_percentage_7_12:
                item && item.credit_percentage_7_12
                  ? item.credit_percentage_7_12
                  : null,
              debit_antecipation_percentage:
                item && item.debit_antecipation_percentage
                  ? item.debit_antecipation_percentage
                  : null,
              credit_antecipation_percentage:
                item && item.credit_antecipation_percentage
                  ? item.credit_antecipation_percentage
                  : null,
              ecommerce_transaction_fee:
                item && item.ecommerce_transaction_fee
                  ? item.ecommerce_transaction_fee
                  : null,
              ecommerce_transaction_percentage:
                item && item.ecommerce_transaction_percentage
                  ? item.ecommerce_transaction_percentage
                  : null,
            }}
            validationSchema={ItemSchema}
            onSubmit={async (values) => {
              const subtotal = format(
                Number(values.price) * values.quantity,
                2,
              );
              let total = subtotal;
              if (values.discount_type === 0) {
                total -= format((values.discount / 100.0) * total, 2);
              } else if (values.discount_type === 1) {
                total = subtotal - values.discount;
              }
              onSubmit({
                ...values,
                price: Number(values.price),
                contract_product_id: values.contract_product.value,
                discount_type:
                  values.discount_type === -1 ? null : values.discount_type,
                discount:
                  values.discount_type === -1 ? null : Number(values.discount),
                debit_percentage:
                  values.debit_percentage === null
                    ? null
                    : Number(values.debit_percentage),
                credit_percentage:
                  values.credit_percentage === null
                    ? null
                    : Number(values.credit_percentage),
                credit_percentage_2_6:
                  values.credit_percentage_2_6 === null
                    ? null
                    : Number(values.credit_percentage_2_6),
                credit_percentage_7_12:
                  values.credit_percentage_7_12 === null
                    ? null
                    : Number(values.credit_percentage_7_12),
                debit_antecipation_percentage:
                  values.debit_antecipation_percentage === null
                    ? null
                    : Number(values.debit_antecipation_percentage),
                credit_antecipation_percentage:
                  values.credit_antecipation_percentage === null
                    ? null
                    : Number(values.credit_antecipation_percentage),
                ecommerce_transaction_fee:
                  values.ecommerce_transaction_fee === null
                    ? null
                    : Number(values.ecommerce_transaction_fee),
                ecommerce_transaction_percentage:
                  values.ecommerce_transaction_percentage === null
                    ? null
                    : Number(values.ecommerce_transaction_percentage),
                total,
              });
            }}
            render={({ values, errors, touched, setFieldValue }) => {
              return (
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Selecione o produto</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        variant="filled"
                        value={values.contract_product.label}
                        style={{ width: '100%' }}
                        error={
                          errors.contract_product && touched.contract_product
                        }
                      >
                        <ListSubheader>SAAS</ListSubheader>
                        {productSaas.map((product) => {
                          return (
                            <MenuItem
                              value={product.label}
                              onClick={() => {
                                setFieldValue('price', product.price);
                                setFieldValue('contract_product', {
                                  label: product.label,
                                  name: product.label,
                                  value: product.id,
                                  type: product.type,
                                  image: product.image,
                                  recurrency_type: product.recurrency_type,
                                  debit_percentage_enabled:
                                    product.debit_percentage_enabled,
                                  credit_percentage_enabled:
                                    product.credit_percentage_enabled,
                                  credit_percentage_2_6_enabled:
                                    product.credit_percentage_2_6_enabled,
                                  credit_percentage_7_12_enabled:
                                    product.credit_percentage_7_12_enabled,
                                  debit_antecipation_percentage_enabled:
                                    product.debit_antecipation_percentage_enabled,
                                  credit_antecipation_percentage_enabled:
                                    product.credit_antecipation_percentage_enabled,
                                  ecommerce_transaction_fee_enabled:
                                    product.ecommerce_transaction_fee_enabled,
                                  ecommerce_transaction_percentage_enabled:
                                    product.ecommerce_transaction_percentage_enabled,
                                  price: product.price,
                                });
                              }}
                            >
                              {product.label}
                            </MenuItem>
                          );
                        })}
                        <ListSubheader>Serviços</ListSubheader>
                        {!addSubscription &&
                          productServices.map((product) => {
                            return (
                              <MenuItem
                                value={product.label}
                                onClick={() => {
                                  setFieldValue('price', product.price);
                                  setFieldValue('contract_product', {
                                    label: product.label,
                                    name: product.label,
                                    value: product.id,
                                    type: product.type,
                                    image: product.image,
                                    recurrency_type: product.recurrency_type,
                                    debit_percentage_enabled:
                                      product.debit_percentage_enabled,
                                    credit_percentage_enabled:
                                      product.credit_percentage_enabled,
                                    credit_percentage_2_6_enabled:
                                      product.credit_percentage_2_6_enabled,
                                    credit_percentage_7_12_enabled:
                                      product.credit_percentage_7_12_enabled,
                                    debit_antecipation_percentage_enabled:
                                      product.debit_antecipation_percentage_enabled,
                                    credit_antecipation_percentage_enabled:
                                      product.credit_antecipation_percentage_enabled,
                                    ecommerce_transaction_fee_enabled:
                                      product.ecommerce_transaction_fee_enabled,
                                    ecommerce_transaction_percentage_enabled:
                                      product.ecommerce_transaction_percentage_enabled,
                                    price: product.price,
                                  });
                                }}
                              >
                                {product.label}
                              </MenuItem>
                            );
                          })}
                        <ListSubheader>Outros</ListSubheader>
                        {!addSubscription &&
                          productOthers.map((product) => {
                            return (
                              <MenuItem
                                value={product.label}
                                onClick={() => {
                                  setFieldValue('price', product.price);
                                  setFieldValue('contract_product', {
                                    name: product.label,
                                    label: product.label,
                                    value: product.id,
                                    type: product.type,
                                    image: product.image,
                                    recurrency_type: product.recurrency_type,
                                    debit_percentage_enabled:
                                      product.debit_percentage_enabled,
                                    credit_percentage_enabled:
                                      product.credit_percentage_enabled,
                                    credit_percentage_2_6_enabled:
                                      product.credit_percentage_2_6_enabled,
                                    credit_percentage_7_12_enabled:
                                      product.credit_percentage_7_12_enabled,
                                    debit_antecipation_percentage_enabled:
                                      product.debit_antecipation_percentage_enabled,
                                    credit_antecipation_percentage_enabled:
                                      product.credit_antecipation_percentage_enabled,
                                    ecommerce_transaction_fee_enabled:
                                      product.ecommerce_transaction_fee_enabled,
                                    ecommerce_transaction_percentage_enabled:
                                      product.ecommerce_transaction_percentage_enabled,
                                    price: product.price,
                                  });
                                }}
                              >
                                {product.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">Dados do item</Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Field
                        name="quantity"
                        label="Quantidade"
                        placeholder="Quantidade"
                        type="number"
                        component={TextField}
                        InputProps={{ inputProps: { min: 1 } }}
                        error={errors.quantity && touched.quantity}
                        helperText={
                          errors.quantity && touched.quantity
                            ? errors.quantity
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Field
                        name="price"
                        label="Preço"
                        placeholder="Preço"
                        currency
                        component={TextField}
                        error={errors.price && touched.price}
                        helperText={
                          errors.price && touched.price ? errors.price : ''
                        }
                        value={Number(values.price)}
                        onChange={({ target }) => {
                          setFieldValue('price', Number(target.value));
                          setFieldValue('contract_product', {
                            ...values.contract_product,
                            price: Number(target.value),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="discount_type"
                        label="Tipo de desconto"
                        placeholder="Tipo de desconto"
                        component={SelectField}
                        options={[
                          { label: 'SEM DESCONTO', value: -1 },
                          { label: 'PERCENTUAL', value: 0 },
                          { label: 'VALOR', value: 1 },
                        ]}
                        error={errors.discount_type && touched.discount_type}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Field
                        name="discount"
                        label="Desconto"
                        placeholder="Desconto"
                        currency={values.discount_type === 1}
                        percentual={values.discount_type === 0}
                        component={TextField}
                        disabled={values.discount_type === -1}
                        value={
                          values.discount_type === -1 ? '' : values.discount
                        }
                        error={errors.discount && touched.discount}
                        helperText={
                          errors.discount && touched.discount
                            ? errors.discount
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="observations"
                        label="Observações"
                        placeholder="Observações"
                        component={RichTextField}
                        defaultValue={item ? item.observations : null}
                      />
                    </Grid>
                    {values.contract_product &&
                      values.contract_product.debit_percentage_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="debit_percentage"
                            label="Taxa de débito (D + 30)"
                            placeholder="Taxa de débito (D + 30)"
                            percentual
                            component={TextField}
                            error={
                              errors.debit_percentage &&
                              touched.debit_percentage
                            }
                            helperText={
                              errors.debit_percentage &&
                              touched.debit_percentage
                                ? errors.debit_percentage
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product.credit_percentage_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="credit_percentage"
                            label="Taxa de crédito (D + 30)"
                            placeholder="Taxa de crédito (D + 30)"
                            percentual
                            component={TextField}
                            error={
                              errors.credit_percentage &&
                              touched.credit_percentage
                            }
                            helperText={
                              errors.credit_percentage &&
                              touched.credit_percentage
                                ? errors.credit_percentage
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product.credit_percentage_2_6_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="credit_percentage_2_6"
                            label="Taxa de crédito (2-6 parcelas)"
                            placeholder="Taxa de crédito (2-6 parcelas)"
                            percentual
                            component={TextField}
                            error={
                              errors.credit_percentage_2_6 &&
                              touched.credit_percentage_2_6
                            }
                            helperText={
                              errors.credit_percentage_2_6 &&
                              touched.credit_percentage_2_6
                                ? errors.credit_percentage_2_6
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product.credit_percentage_7_12_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="credit_percentage_7_12"
                            label="Taxa de crédito (7-12 parcelas)"
                            placeholder="Taxa de crédito (7-12 parcelas)"
                            percentual
                            component={TextField}
                            error={
                              errors.credit_percentage_7_12 &&
                              touched.credit_percentage_7_12
                            }
                            helperText={
                              errors.credit_percentage_7_12 &&
                              touched.credit_percentage_7_12
                                ? errors.credit_percentage_7_12
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product
                        .debit_antecipation_percentage_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="debit_antecipation_percentage"
                            label="Taxa de débito (D + 1)"
                            placeholder="Taxa de débito (D + 1)"
                            percentual
                            component={TextField}
                            error={
                              errors.debit_antecipation_percentage &&
                              touched.debit_antecipation_percentage
                            }
                            helperText={
                              errors.debit_antecipation_percentage &&
                              touched.debit_antecipation_percentage
                                ? errors.debit_antecipation_percentage
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product
                        .credit_antecipation_percentage_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="credit_antecipation_percentage"
                            label="Taxa de crédito (D + 1)"
                            placeholder="Taxa de crédito (D + 1)"
                            percentual
                            component={TextField}
                            error={
                              errors.credit_antecipation_percentage &&
                              touched.credit_antecipation_percentage
                            }
                            helperText={
                              errors.credit_antecipation_percentage &&
                              touched.credit_antecipation_percentage
                                ? errors.credit_antecipation_percentage
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product
                        .ecommerce_transaction_fee_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="ecommerce_transaction_fee"
                            label="Valor da transação"
                            placeholder="Valor da transação"
                            currency
                            component={TextField}
                            error={
                              errors.ecommerce_transaction_fee &&
                              touched.ecommerce_transaction_fee
                            }
                            helperText={
                              errors.ecommerce_transaction_fee &&
                              touched.ecommerce_transaction_fee
                                ? errors.ecommerce_transaction_fee
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    {values.contract_product &&
                      values.contract_product
                        .ecommerce_transaction_percentage_enabled &&
                      !amendment && (
                        <Grid item xs={12}>
                          <Field
                            name="ecommerce_transaction_percentage"
                            label="Taxa"
                            placeholder="Taxa"
                            percentual
                            component={TextField}
                            error={
                              errors.ecommerce_transaction_percentage &&
                              touched.ecommerce_transaction_percentage
                            }
                            helperText={
                              errors.ecommerce_transaction_percentage &&
                              touched.ecommerce_transaction_percentage
                                ? errors.ecommerce_transaction_percentage
                                : ''
                            }
                          />
                        </Grid>
                      )}
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions onClose={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
}
