import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Warning as WarningIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import DialogActions from '../../components/DialogActions';

const CustomDialogActions = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    padding: theme.spacing(3),
    border: 'none',
  },
  button: {
    minWidth: '120px',
    borderRadius: '8px',
    textTransform: 'none',
    fontWeight: 'bold',
  },
  cancelButton: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))(DialogActions);

export default function DeleteConfirmationDialog({
  open,
  onClose,
  onSubmit,
  t,
}) {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          style={{ paddingTop: '6px' }}
        >
          <Grid item>
            <WarningIcon style={{ color: '#ff9800', fontSize: 30 }} />
          </Grid>
          <Grid item>
            <Typography varian t="h1" style={{ fontWeight: 600 }}>
              {t('agentRelease:attention')}!
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          mt={4}
        >
          <Typography variant="subtitle1" align="center">
            {t('agentRelease:deleteConfirm')}
          </Typography>
        </Grid>
      </DialogContent>

      <CustomDialogActions
        submitLabel={t('agentRelease:delete')}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Dialog>
  );
}
