import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import Dialog from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import DialogContent from '../../components/DialogContent';
import DialogActions from '../../components/DialogActions';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';
import FileInput from '../../components/FileInput/FileInput';

const versionRegex = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/;

const VersionSchema = Yup.object().shape({
  device: Yup.string().required('Campo obrigatório'),
  version: Yup.string()
    .matches(
      versionRegex,
      'A versão precisa estar no formato semântico (ex: 1.0.0, 2.3.1-alpha)',
    )
    .required('A versão é obrigatória'),
  url: Yup.mixed()
    .nullable()
    .required('Campo obrigatório'),
});

export default function NewVersionFormDialog({ open, onClose, onSubmit, t }) {
  const upload = async (values) => {
    try {
      const extension = values.url.name.split('.').pop();
      const presignValues = {
        name: `${values.device}/EyePrintAgent-${values.version}.${extension}`,
        type: values.url.type,
        device: values.device,
        version: values.version,
      };

      const response = await gateway
        .post('/tenants/print-agent/presign', presignValues)
        .then(({ data }) => data);

      const { upload_url, object_url } = response;
      await gateway.put(upload_url, values.url, {
        headers: {
          'Content-Type': values.url.type,
          'x-amz-acl': 'public-read',
        },
      });
      return object_url;
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message
        ? t('agentRelease:alreadyReleaseVersion')
        : t('agentRelease:failedAddVersion');
      showError(errorMessage);
      throw new Error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      device: '',
      version: '',
      url: '',
    },
    validationSchema: VersionSchema,
    onSubmit: async (values, formikBag) => {
      formikBag.setSubmitting(true);
      try {
        const object_url = await upload(values);

        await gateway.post('/tenants/print-agent', {
          ...values,
          url: object_url,
        });
        onSubmit(values);
      } catch (err) {
        console.error(err);
      } finally {
        formikBag.setSubmitting(false);
      }
    },
  });

  const {
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const acceptMap = {
    desktop: '.exe,.msi,.zip',
    tablet: '.apk',
  };

  const fieldAcceptMap = acceptMap[values.device] || '.exe,.msi,.zip,.apk';

  return (
    <FormikProvider value={formik}>
      <Dialog onClose={onClose} open={open} maxWidth="sm">
        <DialogTitle onClose={onClose}>
          {t('agentRelease:addVersion')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Field
                name="device"
                label={t('agentRelease:device')}
                placeholder={t('agentRelease:selectDevice')}
                component={SelectField}
                options={[
                  { label: 'Desktop', value: 'desktop' },
                  { label: 'Tablet', value: 'tablet' },
                ]}
                helperText={
                  errors.device && touched.device ? errors.device : ''
                }
                fullWidth
                error={errors.device && touched.device}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Field
                  name="version"
                  label={t('agentRelease:version')}
                  placeholder={t('agentRelease:writeVersion')}
                  component={TextField}
                  fullWidth
                  error={errors.version && touched.version}
                  helperText={
                    errors.version && touched.version ? errors.version : ''
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Field
                  name="url"
                  label={t('agentRelease:addFile')}
                  accept={fieldAcceptMap}
                  component={FileInput}
                  setFieldValue={setFieldValue}
                  error={errors.url && touched.url}
                  helperText={errors.file && touched.file ? errors.file : ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={isSubmitting}
          submitLabel={t('agentRelease:create')}
        />
      </Dialog>
    </FormikProvider>
  );
}
