import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FileInput = ({ setFieldValue, field, form, label, accept }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFieldValue(field.name, file);
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    setFieldValue(field.name, null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        id="file-input"
        style={{ display: 'none' }}
        accept={accept}
        onChange={handleFileChange}
      />

      {!fileName ? (
        <label
          htmlFor="file-input"
          style={{
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: '#4caf50',
            color: '#fff',
            cursor: 'pointer',
            borderRadius: '4px',
          }}
        >
          {label}
        </label>
      ) : (
        <button
          type="button"
          onClick={handleFileRemove}
          style={{
            padding: '10px 20px',
            backgroundColor: '#f44336',
            color: '#fff',
            cursor: 'pointer',
            borderRadius: '4px',
          }}
        >
          {t('agentRelease:removeFile')}
        </button>
      )}

      {form.touched[field.name] && form.errors[field.name] && (
        <div style={{ color: 'red', fontSize: '12px' }}>
          {form.errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default FileInput;
