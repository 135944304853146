import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import clsx from 'clsx';
import TextField from '../../components/TextField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import SelectField from '../../components/SelectField';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import NewVersionFormDialog from './NewVersionFormDialog';

const COLUMNS = [
  { id: 'device', label: 'device', align: 'center' },
  {
    id: 'version',
    label: 'Versão',
    align: 'center',
  },
  { id: 'url', label: 'url' },
  {
    id: 'created_at',
    align: 'right',
    label: 'createdAt',
  },
  { id: 'edit', align: 'center', label: '' },
];

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
    '&.urlCell': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '12px',
      cursor: 'pointer',
    },
  },
}))(TableCell);

const ButtonStyled = withStyles(() => ({
  root: {
    marginLeft: '10px',
    transition: 'background-color 0.3s ease',
  },
}))(Button);

const TableRowStyled = withStyles(() => ({
  root: () => ({
    height: '80px',
  }),
}))(TableRow);

const VersionsTab = ({ show, t }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(false);
  const [versions, setVersions] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [clicked, setClicked] = React.useState(false);

  const [q, setQ] = React.useState('');
  const [device, setDevice] = React.useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [showNewUserFormDialog, setShowNewUserFormDialog] = React.useState(
    false,
  );
  const [deleteVersionId, setDeleteVersionId] = React.useState(null);

  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = React.useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchVersions = async () => {
    setLoading(true);

    try {
      const response = await gateway.get('/tenants/print-agent', {
        params: {
          ...(q.length > 0 ? { q } : {}),
          ...(device !== null && device !== undefined ? { device } : {}),
          limit: rowsPerPage,
          page: page + 1,
        },
      });

      setVersions(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchVersions();
  };

  React.useEffect(() => {
    fetchVersions();
  }, [page, rowsPerPage]);
  if (!show) {
    return null;
  }

  const handleDeleteVersion = async () => {
    try {
      setLoading(true);
      await gateway.delete(`/tenants/print-agent/${deleteVersionId}`);

      const newVersions = versions.filter((e) => e.id !== deleteVersionId);
      setVersions(newVersions);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = (row) => {
    const fileName = `${row.device}-${row.version}`;
    const link = document.createElement('a');
    link.href = row.url;
    link.download = fileName;
    link.click();
  };

  const copyUrl = (url, id) => {
    setClicked(id);
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <Fade in>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box pt={4} mb={2} display="flex" alignItems="center">
              <Box pr={4} mb={2} display="flex" justifyContent="start">
                <TextField
                  name="search"
                  label={t('agentRelease:toLookFor')}
                  placeholder=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="inherit" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={q}
                  onChange={(e) => {
                    setQ(e.target.value);
                  }}
                />
              </Box>
              <Box pr={4} width={160} mb={2}>
                <SelectField
                  name="device"
                  label={t('agentRelease:device')}
                  placeholder={t('agentRelease:device')}
                  options={[
                    { label: 'Desktop', value: 'desktop' },
                    { label: 'Tablet', value: 'tablet' },
                  ]}
                  variant="standard"
                  value={device}
                  onChange={(e) => {
                    setDevice(e.target.value);
                  }}
                />
              </Box>
              <Box pr={4} mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                >
                  {t('agentRelease:search')}
                </Button>
              </Box>
            </Box>
            <Box mb={2} display="flex" alignItems="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowNewUserFormDialog(true)}
                startIcon={<AddIcon />}
              >
                {t('agentRelease:addVersion')}
              </Button>
            </Box>
          </Box>
          <Box pt={4}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((column) => (
                      <ItemsTableCell
                        key={column.id}
                        align={column.align ? column.align : 'left'}
                      >
                        <Typography variant="subtitle2" color="primary">
                          {t(`agentRelease:${column.label}`)}
                        </Typography>
                      </ItemsTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    [1, 2, 3].map((value) => (
                      <TableRow tabIndex={-1} key={value}>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                      </TableRow>
                    ))}
                  {!loading &&
                    versions.map((row) => {
                      return (
                        <TableRowStyled key={row.id}>
                          <ItemsTableCell
                            style={{ textTransform: 'capitalize' }}
                            align="center"
                          >
                            {row.device}
                          </ItemsTableCell>
                          <ItemsTableCell align="center">
                            {row.version}
                          </ItemsTableCell>
                          <ItemsTableCell className="urlCell">
                            <div onClick={() => handleDownloadClick(row)}>
                              {row.url}
                            </div>
                            <ButtonStyled
                              variant="outlined"
                              color="primary"
                              onClick={() => copyUrl(row.url, row.id)}
                            >
                              {clicked === row.id
                                ? t('agentRelease:copied')
                                : t('agentRelease:copy')}
                            </ButtonStyled>
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            {moment(row.created).format('DD/MM/YYYY HH:mm')}
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            <div
                              className={clsx('ic-close-red ic-sm2')}
                              onClick={() => {
                                setShowDeleteConfirmationDialog(true, row.id);
                                setDeleteVersionId(row.id);
                              }}
                            />
                          </ItemsTableCell>
                        </TableRowStyled>
                      );
                    })}
                  {!loading && versions.length === 0 && (
                    <TableRow>
                      <ItemsTableCell colSpan={COLUMNS.length} align="center">
                        <Box m={4}>
                          <Typography>
                            {t('agentRelease:notVersions')}
                          </Typography>
                        </Box>
                      </ItemsTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Resultados por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Fade>
      <NewVersionFormDialog
        open={showNewUserFormDialog}
        t={t}
        onClose={() => {
          setShowNewUserFormDialog(false);
        }}
        onSubmit={() => {
          fetchVersions();
          setShowNewUserFormDialog(false);
        }}
      />

      <DeleteConfirmationDialog
        open={showDeleteConfirmationDialog}
        onClose={() => {
          setShowDeleteConfirmationDialog(false);
        }}
        onSubmit={() => {
          handleDeleteVersion();
          setShowDeleteConfirmationDialog(false);
        }}
        t={t}
      />
    </>
  );
};

export default VersionsTab;
