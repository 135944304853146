import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import organizationRelationship from '../../../constants/organizationRelationship';
import contractModels from '../../../constants/contractModels';
import billingOwner from '../../../constants/billingOwner';
import Autocomplete from '../../../components/Autocomplete';
import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';
import gateway from '../../../utils/gateway';
import { showError } from '../../../utils/toast';
import Loading from '../../../components/Loading';

const organizationRelationshipOptions = [
  { value: organizationRelationship.business, label: 'Matriz' },
  { value: organizationRelationship.franchise, label: 'Franqueador' },
  { value: organizationRelationship.child, label: 'Filial/Franqueado' },
];

const billingOwnerOptions = [
  { value: 'self', label: 'Este negócio' },
  { value: 'associates', label: 'Os negócios associados' },
];

const ProposalSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  contract_type: Yup.number().required('Campo obrigatório'),
  tenant_owner: Yup.object()
    .nullable()
    .when('contract_type', {
      is: 2,
      then: Yup.object().required('Campo obrigatório'),
    }),
  organization_id: Yup.string()
    .nullable()
    .when('organization_relationship', {
      is: organizationRelationship.child,
      then: Yup.string().required('Campo obrigatório'),
    }),
  organization_name: Yup.string()
    .nullable()
    .when(['organization_relationship', 'billing_owner'], {
      is: (organization_relationship, billing_owner) =>
        [
          organizationRelationship.business,
          organizationRelationship.franchise,
        ].includes(organization_relationship) &&
        billing_owner === billingOwner.associates,
      then: Yup.string()
        .max(127, 'Nome deve conter até 127 caracteres')
        .required('Campo obrigatório'),
    }),
  organization_relationship: Yup.mixed().oneOf([
    null,
    ...Object.values(organizationRelationship),
  ]),
  billing_owner: Yup.mixed().oneOf([null, ...Object.values(billingOwner)]),
});

const useStyles = makeStyles(() => ({
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const TenantStep = ({
  show,
  step,
  total,
  onNextStep,
  context,
  label,
  guided,
  edit,
  isOrganizationParent,
  spot,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [tenant, setTenant] = React.useState(context.tenant || null);
  const [
    defaultOrganizationOptions,
    setDefaultOrganizationOptions,
  ] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const formRef = React.useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const fetchTenants = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/tenants', {
        params: {
          search,
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((tenant) => ({
        ...tenant,
        value: tenant.id,
        label: tenant.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const fetchContracts = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/contracts', {
        params: {
          search,
          status: [4],
          ...(tenant !== null ? { tenant_id: tenant.id } : {}),
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((contract) => ({
        ...contract,
        value: contract.id,
        label: contract.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const fetchPipedriveDeals = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/pipedrive/deals', {
        params: {
          search,
        },
      });

      options = response.data.map((deal) => ({
        value: deal.id,
        label: deal.title,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const fetchOrganizations = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/tenants/organizations', {
        params: {
          offset: 0,
          limit: 50,
          search,
        },
      });

      options = (response?.data?.data || []).map((organization) => ({
        value: String(organization.id),
        label: organization.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const preFetchOrganization = async () => {
    setIsLoading(true);
    const defaultOrganizations = await fetchOrganizations();

    setDefaultOrganizationOptions(defaultOrganizations || []);
    setIsLoading(false);
  };

  React.useEffect(() => {
    preFetchOrganization();
  }, []);

  if (!show) {
    return null;
  }

  const existingTenant = !!context.tenant;

  if (isLoading) {
    return (
      <Fade in>
        <Paper square elevation={2} style={{ flex: 1 }}>
          <Loading isSmall show={isLoading} />
        </Paper>
      </Fade>
    );
  }

  return (
    <Fade in>
      <Paper square elevation={2} style={{ flex: 1 }}>
        <Box p={0} display="flex" flexDirection="column">
          <Box p={4} pt={6} display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="primary">
              Passo {step + 1} de {total}
            </Typography>
            <Typography variant="h4">
              {label || 'Selecionar Cliente'}
            </Typography>
          </Box>
          <Box p={4} pt={4} pb={2} display="flex" flexDirection="column">
            <Formik
              innerRef={formRef}
              validateOnMount
              initialValues={{
                tenant: context.tenant || null,
                contract: context.contract || null,
                deal: context.deal_key || null,
                contract_type: spot ? 6 : 0,
                payment_provider: 1,
                tenant_owner: context.tenant_owner || null,
                name: context.name || context.tenant?.name || '',
                organization_relationship:
                  context.organization_relationship ||
                  organizationRelationship.business,
                organization_id: context?.organization_id || null,
                organization_name: context.organization_name || '',
                billing_owner: context.billing_owner || billingOwner.self,
              }}
              validationSchema={ProposalSchema}
              onSubmit={(values) => {
                onNextStep({
                  ...context,
                  ...values,
                  deal_key: values.deal,
                });
              }}
              render={({ values, errors, touched, setFieldValue, isValid }) => {
                return (
                  <>
                    <form>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Vincular proposta a cliente da base
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="tenant"
                            label="Cliente"
                            placeholder="Cliente"
                            component={Autocomplete}
                            fetch={fetchTenants}
                            disabled={guided || existingTenant}
                            loadingText="Carregando..."
                            noOptionsText="Nenhum cliente encontrado"
                            error={errors.tenant && touched.tenant}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('tenant', value);
                              setFieldValue('contract', null);

                              if (value) {
                                setTenant(value);
                                setFieldValue('name', value.name);
                              } else {
                                setTenant(null);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Vincular proposta a contrato
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="contract"
                            label="Contrato"
                            placeholder="Contrato"
                            component={Autocomplete}
                            fetch={fetchContracts}
                            disabled={guided || existingTenant}
                            loadingText="Carregando..."
                            noOptionsText="Nenhum contrato encontrado"
                            error={errors.contract && touched.contract}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('contract', value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Vincular proposta a negócio do pipedrive
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="deal"
                            label="Negócio"
                            placeholder="Negócio"
                            component={Autocomplete}
                            fetch={fetchPipedriveDeals}
                            loadingText="Carregando..."
                            noOptionsText="Nenhum negócio encontrado"
                            error={errors.deal && touched.deal}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('deal', value);

                              if (value) {
                                setFieldValue('name', value.label);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">Tipo de contrato</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              disabled={existingTenant || spot}
                              name="contract_type"
                              label="Tipo de contrato"
                              placeholder="Tipo de contrato"
                              component={SelectField}
                              options={contractModels}
                              error={
                                errors.contract_type && touched.contract_type
                              }
                              onChange={(e) => {
                                const { value } = e.target;
                                setFieldValue('contract_type', value);
                              }}
                            />
                          </Grid>
                        </Grid>
                        {values.contract_type === 2 && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Empresa franqueadora
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                name="tenant_owner"
                                label="Franqueador"
                                placeholder="Franqueador"
                                component={Autocomplete}
                                fetch={fetchTenants}
                                loadingText="Carregando..."
                                noOptionsText="Nenhuma empresa encontrada"
                                error={
                                  errors.tenant_owner && touched.tenant_owner
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setFieldValue('tenant_owner', value);
                                }}
                                helperText={
                                  errors.tenant_owner && touched.tenant_owner
                                    ? errors.tenant_owner
                                    : ''
                                }
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h5">Tipo de negócio</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              name="organization_relationship"
                              label="Tipo de negócio"
                              placeholder="Tipo de negócio"
                              component={SelectField}
                              options={organizationRelationshipOptions}
                              disabled={isOrganizationParent}
                              error={
                                errors.organization_relationship &&
                                touched.organization_relationship
                              }
                              onChange={(e) => {
                                const { value } = e.target;

                                if (value) {
                                  if (
                                    value === organizationRelationship.child
                                  ) {
                                    setFieldValue('organization_id', null);
                                    setFieldValue('organization_name', '');
                                    setFieldValue(
                                      'billing_owner',
                                      billingOwner.self,
                                    );
                                  }

                                  setFieldValue(
                                    'organization_relationship',
                                    value,
                                  );
                                } else {
                                  setFieldValue('organization_id', null);
                                  setFieldValue(
                                    'organization_relationship',
                                    organizationRelationship.franchise,
                                  );
                                  setFieldValue(
                                    'billing_owner',
                                    billingOwner.self,
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                        {isOrganizationParent && (
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Não é permitido alterar o tipo de negócio.
                            </Typography>
                          </Grid>
                        )}
                        {values.organization_relationship ===
                          organizationRelationship.child && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Organização controladora
                              </Typography>
                            </Grid>
                            <Grid item container spacing={2}>
                              <Grid item xs={12}>
                                <Field
                                  name="organization_id"
                                  label="Organização controladora"
                                  placeholder="Organização controladora"
                                  component={Autocomplete}
                                  fetch={fetchOrganizations}
                                  defaultOptions={defaultOrganizationOptions}
                                  loadingText="Carregando..."
                                  noOptionsText="Nenhuma organização encontrada"
                                  error={
                                    errors.organization_id &&
                                    touched.organization_id
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;

                                    if (value) {
                                      setFieldValue('organization_id', value);
                                    } else {
                                      setFieldValue('organization_id', null);
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Responsável pelo pagamento da assinatura
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              disabled={
                                values.organization_relationship ===
                                  organizationRelationship.child ||
                                isOrganizationParent
                              }
                              name="billing_owner"
                              label="Responsável pelo pagamento da assinatura"
                              placeholder="Responsável pelo pagamento da assinatura"
                              component={SelectField}
                              options={billingOwnerOptions}
                              onChange={(e) => {
                                const { value } = e.target;

                                setFieldValue('organization_name', '');
                                if (value) {
                                  setFieldValue('billing_owner', value);
                                } else {
                                  setFieldValue('billing_owner', null);
                                }
                              }}
                              error={
                                errors.billing_owner && touched.billing_owner
                              }
                            />
                          </Grid>
                        </Grid>
                        {values.organization_relationship ===
                          organizationRelationship.child && (
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Não é permitido alterar o responsável pela
                              assinatura. O organizador já determinou que os
                              associados serão responsáveis pelo pagamento.
                            </Typography>
                          </Grid>
                        )}
                        {isOrganizationParent && (
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Não é permitido alterar o responsável pela
                              assinatura.
                            </Typography>
                          </Grid>
                        )}
                        {values.organization_relationship !==
                          organizationRelationship.child &&
                          values.billing_owner !== billingOwner.self && (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="h5">
                                  Nome da organização controladora
                                </Typography>
                              </Grid>
                              <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                  <Field
                                    name="organization_name"
                                    label="Nome da organização controladora"
                                    placeholder="Nome da organização controladora"
                                    component={TextField}
                                    disabled={
                                      values.organization_member_type ===
                                      organizationRelationship.child
                                    }
                                    error={
                                      errors.organization_name &&
                                      touched.organization_name
                                    }
                                    helperText={
                                      errors.organization_name &&
                                      touched.organization_name
                                        ? errors.organization_name
                                        : ''
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Identificação da proposta
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              name="name"
                              label="Nome"
                              placeholder="Nome"
                              component={TextField}
                              disabled={
                                values.tenant !== null || values.deal !== null
                              }
                              error={errors.name && touched.name}
                              helperText={
                                errors.name && touched.name ? errors.name : ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                    <Box
                      pt={2}
                      pb={0}
                      pl={4}
                      pr={4}
                      mt={4}
                      display="flex"
                      justifyContent="space-between"
                      borderTop="1px solid #ededed"
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => history.goBack()}
                        className={classes.cancel}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.confirm}
                        endIcon={<ChevronRightIcon />}
                        disabled={!isValid}
                      >
                        Próximo
                      </Button>
                    </Box>
                  </>
                );
              }}
            />
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default TenantStep;
