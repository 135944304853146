import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const ItemsTableCell = withStyles(() => ({
  root: {
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const DetailTableCell = withStyles(() => ({
  root: {
    padding: 0,
    border: 0,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 16,
    width: 52,
    height: 52,
  },
}));

const ItemsTab = ({ show, proposal }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!show || !proposal) {
    return null;
  }

  const COLUMNS = [
    {
      id: 'product',
      label: 'PRODUTO',
    },
    { id: 'quantity', label: 'QTD.' },
    { id: 'price', label: 'PREÇO' },
    { id: 'discount', label: 'DESC.' },
    { id: 'total', align: 'right', label: 'TOTAL' },
  ];

  const totalRecurrent = proposal.proposal_items.reduce((sum, item) => {
    if (item.contract_product.recurrency_type === 0) {
      return sum;
    }
    return sum + item.total;
  }, 0);

  const formattedTotalRecurrent = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalRecurrent);

  const totalSpot = proposal.proposal_items.reduce((sum, item) => {
    if (item.contract_product.recurrency_type !== 0) {
      return sum;
    }
    return sum + item.total;
  }, 0);

  const formattedTotalSpot = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalSpot);

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <Box mb={4} display="flex" flexDirection="column">
          <Box mt={2} display="flex" alignItems="center" gridGap={12}>
            <Typography variant="h6">Itens recorrentes</Typography>
            {proposal.type === 1 ? (
              <Alert severity="warning" size="small">
                Não irá gerar recorrência, pois é uma cobrança avulsa
              </Alert>
            ) : null}
          </Box>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <ItemsTableCell
                      key={column.id}
                      align={column.align ? column.align : 'left'}
                    >
                      <Typography variant="subtitle2" color="primary">
                        {column.label}
                      </Typography>
                    </ItemsTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {proposal === null &&
                  [1, 2, 3].map((value) => (
                    <TableRow tabIndex={-1} key={value}>
                      <ItemsTableCell>
                        <Box display="flex" alignItems="center">
                          <Box mr={2}>
                            <Skeleton variant="circle" width={24} height={24} />
                          </Box>
                          <Skeleton variant="text" width={80} />
                        </Box>
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                    </TableRow>
                  ))}
                {proposal !== null &&
                  proposal.proposal_items
                    .filter((row) => row.recurrency_type !== 0)
                    .map((row) => {
                      const formattedDiscount = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.discount);

                      const formattedPrice = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.price);

                      const formattedTotal = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.total);

                      let measure = '';
                      if (proposal.type === 1) {
                        measure = '';
                      } else if (row.measure === 1) {
                        measure = ' / mês';
                      } else if (row.measure === 2) {
                        measure = ' / hora';
                      } else if (row.measure === 3) {
                        measure = ' / usuário';
                      } else if (row.measure === 4) {
                        measure = ' / usuário / mês';
                      } else if (row.measure === 5) {
                        measure = ' / ano';
                      } else if (row.measure === 6) {
                        measure = ' / usuário / ano';
                      }

                      let recurrency = '';
                      if (proposal.type === 1) {
                        recurrency = '';
                      } else if (row.recurrency_type === 1) {
                        recurrency = ' / mês';
                      } else if (row.recurrency_type === 2) {
                        recurrency = ' / ano';
                      }

                      return (
                        <React.Fragment key={row.id}>
                          <TableRow tabIndex={-1}>
                            <ItemsTableCell>
                              <Box display="flex" alignItems="center">
                                <Box mr={2}>
                                  <img
                                    src={row.contract_product.image}
                                    alt={row.name}
                                    className={classes.image}
                                  />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                  <Typography>{row.name}</Typography>
                                  {row.credit_percentage !== null ? (
                                    <Typography variant="body2">{`Taxa de crédito ${row.credit_percentage}%`}</Typography>
                                  ) : null}
                                  {row.debit_percentage !== null ? (
                                    <Typography variant="body2">{`Taxa de débito ${row.debit_percentage}%`}</Typography>
                                  ) : null}
                                  {row.credit_percentage_2_6 !== null ? (
                                    <Typography variant="body2">{`Taxa de crédito (2-6 parcelas) ${row.credit_percentage_2_6}%`}</Typography>
                                  ) : null}
                                  {row.credit_percentage_7_12 !== null ? (
                                    <Typography variant="body2">{`Taxa de crédito (7-12 parcelas) ${row.credit_percentage_7_12}%`}</Typography>
                                  ) : null}
                                  {row.debit_antecipation_percentage !==
                                  null ? (
                                    <Typography variant="body2">{`Taxa de débito antecipado ${row.debit_antecipation_percentage}%`}</Typography>
                                  ) : null}
                                  {row.credit_antecipation_percentage !==
                                  null ? (
                                    <Typography variant="body2">{`Taxa de crédito antecipado ${row.credit_antecipation_percentage}%`}</Typography>
                                  ) : null}
                                  {row.ecommerce_transaction_fee !== null ? (
                                    <Typography variant="body2">{`Valor da transação ${Intl.NumberFormat(
                                      'pt-BR',
                                      {
                                        style: 'currency',
                                        currency: 'BRL',
                                      },
                                    ).format(
                                      row.ecommerce_transaction_fee,
                                    )}`}</Typography>
                                  ) : null}
                                  {row.ecommerce_transaction_percentage !==
                                  null ? (
                                    <Typography variant="body2">{`Taxa ${row.ecommerce_transaction_percentage}%`}</Typography>
                                  ) : null}
                                </Box>
                              </Box>
                            </ItemsTableCell>
                            <ItemsTableCell>
                              <Typography>{row.quantity}</Typography>
                            </ItemsTableCell>
                            <ItemsTableCell>
                              <Typography>
                                {formattedPrice + measure}
                              </Typography>
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.discount > 0 && row.discount_type === 0 && (
                                <Typography>{row.discount}%</Typography>
                              )}
                              {row.discount > 0 && row.discount_type === 1 && (
                                <Typography>{formattedDiscount}</Typography>
                              )}
                            </ItemsTableCell>
                            <ItemsTableCell align="right">
                              <Typography>
                                {formattedTotal + recurrency}
                              </Typography>
                            </ItemsTableCell>
                          </TableRow>
                          {row.observations && row.observations.length > 0 && (
                            <TableRow tabIndex={-1}>
                              <DetailTableCell colSpan={5}>
                                <Box
                                  p={1}
                                  pl={4}
                                  pr={4}
                                  bgcolor={fade(
                                    theme.palette.text.primary,
                                    0.1,
                                  )}
                                  dangerouslySetInnerHTML={{
                                    __html: row.observations,
                                  }}
                                />
                              </DetailTableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    })}
                {proposal !== null &&
                  proposal.proposal_items.filter(
                    (row) => row.recurrency_type !== 0,
                  ).length === 0 && (
                    <TableRow>
                      <ItemsTableCell colSpan={COLUMNS.length} align="center">
                        <Box p={2}>
                          <Typography>Nenhum item encontrado</Typography>
                        </Box>
                      </ItemsTableCell>
                    </TableRow>
                  )}
                {proposal !== null && proposal.proposal_items.length > 0 && (
                  <TableRow>
                    <ItemsTableCell colSpan={COLUMNS.length - 1} />
                    <ItemsTableCell align="right">
                      <Typography variant="h6">
                        Total {`${formattedTotalRecurrent}`}
                      </Typography>
                    </ItemsTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mb={4} display="flex" flexDirection="column">
          <Typography variant="h5">Outros itens</Typography>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <ItemsTableCell
                      key={column.id}
                      align={column.align ? column.align : 'left'}
                    >
                      <Typography variant="subtitle2" color="primary">
                        {column.label}
                      </Typography>
                    </ItemsTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {proposal === null &&
                  [1, 2, 3].map((value) => (
                    <TableRow tabIndex={-1} key={value}>
                      <ItemsTableCell>
                        <Box display="flex" alignItems="center">
                          <Box mr={2}>
                            <Skeleton variant="circle" width={24} height={24} />
                          </Box>
                          <Skeleton variant="text" width={80} />
                        </Box>
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                    </TableRow>
                  ))}
                {proposal !== null &&
                  proposal.proposal_items
                    .filter((row) => row.recurrency_type === 0)
                    .map((row) => {
                      const formattedDiscount = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.discount);

                      const formattedPrice = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.price);

                      const formattedTotal = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.total);

                      let measure = '';
                      if (row.measure === 1) {
                        measure = ' / mês';
                      } else if (row.measure === 2) {
                        measure = ' / hora';
                      } else if (row.measure === 3) {
                        measure = ' / usuário';
                      } else if (row.measure === 4) {
                        measure = ' / usuário / mês';
                      } else if (row.measure === 5) {
                        measure = ' / ano';
                      } else if (row.measure === 6) {
                        measure = ' / usuário / ano';
                      }

                      let recurrency = '';
                      if (row.recurrency_type === 1) {
                        recurrency = ' / mês';
                      } else if (row.recurrency_type === 2) {
                        recurrency = ' / ano';
                      }

                      return (
                        <TableRow tabIndex={-1} key={row.id}>
                          <ItemsTableCell>
                            <Box display="flex" alignItems="center">
                              <Box mr={2}>
                                <img
                                  src={row.contract_product.image}
                                  alt={row.name}
                                  className={classes.image}
                                />
                              </Box>
                              <Box display="flex" flexDirection="column">
                                <Typography>{row.name}</Typography>
                                {row.credit_percentage !== null ? (
                                  <Typography variant="body2">{`Taxa de crédito ${row.credit_percentage}%`}</Typography>
                                ) : null}
                                {row.debit_percentage !== null ? (
                                  <Typography variant="body2">{`Taxa de débito ${row.debit_percentage}%`}</Typography>
                                ) : null}
                                {row.credit_percentage_2_6 !== null ? (
                                  <Typography variant="body2">{`Taxa de crédito (2-6 parcelas) ${row.credit_percentage_2_6}%`}</Typography>
                                ) : null}
                                {row.credit_percentage_7_12 !== null ? (
                                  <Typography variant="body2">{`Taxa de crédito (7-12 parcelas) ${row.credit_percentage_7_12}%`}</Typography>
                                ) : null}
                                {row.debit_antecipation_percentage !== null ? (
                                  <Typography variant="body2">{`Taxa de débito antecipado ${row.debit_antecipation_percentage}%`}</Typography>
                                ) : null}
                                {row.credit_antecipation_percentage !== null ? (
                                  <Typography variant="body2">{`Taxa de crédito antecipado ${row.credit_antecipation_percentage}%`}</Typography>
                                ) : null}
                                {row.ecommerce_transaction_fee !== null ? (
                                  <Typography variant="body2">{`Valor da transação ${Intl.NumberFormat(
                                    'pt-BR',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    },
                                  ).format(
                                    row.ecommerce_transaction_fee,
                                  )}`}</Typography>
                                ) : null}
                                {row.ecommerce_transaction_percentage !==
                                null ? (
                                  <Typography variant="body2">{`Taxa ${row.ecommerce_transaction_percentage}%`}</Typography>
                                ) : null}
                              </Box>
                            </Box>
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Typography>{row.quantity}</Typography>
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Typography>{formattedPrice + measure}</Typography>
                          </ItemsTableCell>
                          <ItemsTableCell>
                            {row.discount > 0 && row.discount_type === 0 && (
                              <Typography>{row.discount}%</Typography>
                            )}
                            {row.discount > 0 && row.discount_type === 1 && (
                              <Typography>{formattedDiscount}</Typography>
                            )}
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            <Typography>
                              {formattedTotal + recurrency}
                            </Typography>
                          </ItemsTableCell>
                        </TableRow>
                      );
                    })}
                {proposal !== null &&
                  proposal.proposal_items.filter(
                    (row) => row.recurrency_type === 0,
                  ).length === 0 && (
                    <TableRow>
                      <ItemsTableCell colSpan={COLUMNS.length} align="center">
                        <Box p={2}>
                          <Typography>Nenhum item encontrado</Typography>
                        </Box>
                      </ItemsTableCell>
                    </TableRow>
                  )}
                {proposal !== null && proposal.proposal_items.length > 0 && (
                  <TableRow>
                    <ItemsTableCell colSpan={COLUMNS.length - 1} />
                    <ItemsTableCell align="right">
                      <Typography variant="h6">
                        Total {`${formattedTotalSpot}`}
                      </Typography>
                    </ItemsTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fade>
  );
};

export default ItemsTab;
