import React from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs';

import VersionsTab from './VersionsTab';

const AgentReleasePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Typography variant="h4">
                  {t('agentRelease:printAgent')}
                </Typography>
              </Box>
              <Tabs>
                <VersionsTab label={t('agentRelease:manageVersions')} t={t} />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
    </>
  );
};

export default AgentReleasePage;
